<template>
  <div class="gowuye">
    <Head carouseType="ABOUT_US"></Head>
    <div class="gowuye-titles">
      <p class="gowuye-title">{{ $store.state.staticName.AboutUs }}</p>
    </div>
    <div class="gowuye-main">
      <div class="gowuye-main-xian"></div>
      <div class="gowuye-main-nav" ref="navtag">
        <div class="active" @click="isActive('0')">
          {{ $store.state.staticName.company_profiles }}
        </div>
        <div @click="isActive('1')">{{ $store.state.staticName.glories }}</div>
        <!--        <div @click="isActive('2')">{{ $store.state.staticName.client }}</div>-->
        <div @click="isActive('2')">
          {{ $store.state.staticName.company_photo }}
        </div>
        <div @click="isActive('3')">{{ $store.state.staticName.video }}</div>
      </div>
      <!--      公司简绍-->
      <div class="gowuye-main-box" v-if="activeShow === '0'">
        <div class="itme">
          <div class="item-cent">
            <span class="gowuye-main-box-lable"
              >{{ $store.state.staticName.company_name }}：</span
            >
            <span class="gowuye-main-box-value">{{ CPyProfile.cpyName }}</span>
          </div>
          <div class="xian"></div>
        </div>
        <div class="itme">
          <div class="item-cent">
            <span class="gowuye-main-box-lable"
              >{{ $store.state.staticName.create_date }}：</span
            >
            <span class="gowuye-main-box-value">{{
              CPyProfile.createDate
            }}</span>
          </div>
          <div class="xian"></div>
        </div>
        <div class="itme">
          <div class="item-cent">
            <span class="gowuye-main-box-lable"
              >{{ $store.state.staticName.address }}：</span
            >
            <span class="gowuye-main-box-value">{{
              CPyProfile.cpyAddress
            }}</span>
          </div>
          <div class="xian"></div>
        </div>
        <div class="itme">
          <div class="item-cent">
            <span class="gowuye-main-box-lable"
              >{{ $store.state.staticName.legal_person }}：</span
            >
            <span class="gowuye-main-box-value">{{
              CPyProfile.representative
            }}</span>
          </div>
          <div class="xian"></div>
        </div>
        <div class="itme">
          <div class="item-cent">
            <span class="gowuye-main-box-lable"
              >{{ $store.state.staticName.office_clerk }}：</span
            >
            <span class="gowuye-main-box-value">{{ CPyProfile.people }}人</span>
          </div>
          <div class="xian"></div>
        </div>
        <div class="itme">
          <div class="item-cent">
            <span class="gowuye-main-box-lable"
              >{{ $store.state.staticName.technical_support }}：</span
            >
            <span class="gowuye-main-box-value">{{
              CPyProfile.technical
            }}</span>
          </div>
          <div class="xian"></div>
        </div>
        <div class="itme">
          <div class="item-cent">
            <span class="gowuye-main-box-lable"
              >{{ $store.state.staticName.business_scope }}：</span
            >
            <span class="gowuye-main-box-value">{{ CPyProfile.scope }}</span>
          </div>
          <div class="xian"></div>
        </div>
        <div class="itme">
          <div class="item-cent">
            <span class="gowuye-main-box-lable"
              >{{ $store.state.staticName.Company_certification }}：</span
            >
            <span class="gowuye-main-box-value">{{
              CPyProfile.authentication
            }}</span>
          </div>
          <div class="xian"></div>
        </div>
        <div class="itme">
          <div class="item-cent">
            <span class="gowuye-main-box-lable"
              >{{ $store.state.staticName.production_capacity }}：</span
            >
            <span class="gowuye-main-box-value">{{ CPyProfile.capacity }}</span>
          </div>
          <div class="xian"></div>
        </div>
        <div class="itme">
          <div class="item-cent">
            <span class="gowuye-main-box-lable"
              >{{ $store.state.staticName.management_mode }}：</span
            >
            <span class="gowuye-main-box-value">{{
              CPyProfile.management
            }}</span>
          </div>
          <div class="xian"></div>
        </div>
        <div class="itme">
          <div class="item-cent">
            <span class="gowuye-main-box-lable"
              >{{ $store.state.staticName.domestic_client }}：</span
            >
            <span class="gowuye-main-box-value">{{ CPyProfile.domestic }}</span>
          </div>
          <div class="xian"></div>
        </div>
        <div class="itme">
          <div class="item-cent">
            <span class="gowuye-main-box-lable"
              >{{ $store.state.staticName.overseas_customers }}：</span
            >
            <span class="gowuye-main-box-value">{{ CPyProfile.foreign }}</span>
          </div>
          <div class="xian"></div>
        </div>
        <div class="itme">
          <div class="item-cent">
            <span class="gowuye-main-box-lable">URL：</span>
            <span class="gowuye-main-box-value">{{ CPyProfile.url }}</span>
          </div>
          <div class="xian"></div>
        </div>
      </div>
      <!--      公司荣誉-->
      <div class="gowuye-main-box" v-if="activeShow === '1'">
        <div class="rongyu">
          <div
            class="rongyu-img"
            v-for="(item, i) in rongYuList"
            :key="'img' + i"
          >
            <el-image
              style="width: 100%; height: 100%"
              :src="item.abbreviation[0]"
              :preview-src-list="item.abbreviation"
            >
            </el-image>
          </div>
        </div>
        <new-pagination
          @current-change="handleCurrentChange"
          :page-size="page.pageSize"
          layout=" prev, pager, next"
          :total="total"
        ></new-pagination>
      </div>
      <!--      合作客户-->
      <!--      <div class="gowuye-main-box" v-if="activeShow==='2'">-->
      <!--        <div class="rongyu">-->
      <!--&lt;!&ndash;          <div class="rongyu-img" v-for="(item,i) in rongYuList" :key="'img'+i">&ndash;&gt;-->
      <!--&lt;!&ndash;            <el-image&ndash;&gt;-->
      <!--&lt;!&ndash;                style="width: 100%; height: 100%"&ndash;&gt;-->
      <!--&lt;!&ndash;                :src="item"&ndash;&gt;-->
      <!--&lt;!&ndash;                :preview-src-list="rongYuList">&ndash;&gt;-->
      <!--&lt;!&ndash;            </el-image>&ndash;&gt;-->
      <!--&lt;!&ndash;          </div>&ndash;&gt;-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      合作客户-->
      <div class="gowuye-main-box" v-if="activeShow === '2'">
        <div v-if="displayFlg === 'list'">
          <div class="rongyu">
            <div
              class="co-operation-img"
              v-for="(item, i) in CPYAlbum"
              :key="'img' + i"
            >
              <el-image
                style="
                  width: 100%;
                  height: 85%;
                  border-top-left-radius: 20rem;
                  border-top-right-radius: 20rem;
                "
                :src="item.abbreviations[0]"
                :preview-src-list="item.abbreviations"
              >
              </el-image>
              <div class="title" @click="goDetail(item.id)">
                {{ item.title }}
              </div>
              <div class="context">{{ item.description }}</div>
            </div>
          </div>
          <new-pagination
            @current-change="handleCurrentChange1"
            :page-size="page1.pageSize"
            layout=" prev, pager, next"
            :total="total1"
          ></new-pagination>
        </div>
        <div v-if="displayFlg === 'detail'">
          <div class="detail-title">
            {{ detail.title }}
          </div>
          <div class="detail-context">
            {{ detail.description }}
          </div>
          <div style="text-align: center">
            <el-image
              style="
                width: 50%;
                border-top-left-radius: 20rem;
                border-top-right-radius: 20rem;
              "
              :src="detail.abbreviation[0]"
              :preview-src-list="detail.abbreviation"
            ></el-image>
          </div>
        </div>
      </div>
      <!--      视频&相册-->
      <div class="gowuye-main-box" v-if="activeShow === '3'">
        <div class="rongyu">
          <div
            class="rongyu-img"
            v-for="(item, i) in CPYVideo"
            :key="'img' + i"
          >
            <video
              @click="openVideo(item.video[0])"
              class="rongyu-img"
              :src="item.video[0]"
              v-if="validFileType(item.video[0])"
            ></video>
            <el-image
              :preview-src-list="[item.video]"
              class="suo-img"
              :src="item.video[0]"
              v-else
            ></el-image>
          </div>
        </div>
        <new-pagination
          @current-change="handleCurrentChange2"
          :page-size="page2.pageSize"
          layout=" prev, pager, next"
          :total="total2"
        ></new-pagination>
      </div>
    </div>
    <!--    视屏播放弹框-->
    <el-dialog
      :visible.sync="dialogVideo"
      class="myvideo"
      :before-close="handleClose"
    >
      <video autoplay class="myvideo1" :src="video"></video>
    </el-dialog>
    <Foot></Foot>
  </div>
</template>

<script>
import Head from "@/components/Head";
import Foot from "@/components/Foot";
import { mapState } from "vuex";
export default {
  name: "GoWuYue",
  components: {
    Head,
    Foot,
  },
  data() {
    return {
      dialogVideo: false,
      CPyProfile: {},
      total: 0,
      total1: 0,
      total2: 0,
      page: {
        currentPage: 1,
        pageSize: 9,
        input: "",
      },
      page1: {
        currentPage: 1,
        pageSize: 9,
      },
      page2: {
        currentPage: 1,
        pageSize: 9,
        input: "",
      },
      activeShow: "0",
      displayFlg: "list",
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      rongYuList: [],
      CPYAlbum: [],
      CPYVideo: [],
      video: "",
    };
  },
  watch: {
    "$route.query.w": {
      handler(newVal) {
        this.reFresh();
        // this.fenleId = newVal
      },
    },
    "$store.state.languageType": {
      handler(newVal) {
        this.getCPYProfiles();
        // this.getCPYHonor();
        this.getCPYAlbum();
        // this.getCPYVideo();
      },
      // deep: true // 深度监听
    },
  },
  computed: {
    ...mapState({
      languageType: (state) => state.languageType, // 语言类型
    }),
  },
  created() {
    this.reFresh();
  },
  methods: {
    reFresh() {
      let active = this.$route.query.w;

      let q = setTimeout(() => {
        if (active) {
          this.isActive(active);
        } else {
          this.isActive("0");
        }
        clearTimeout(q);
      }, 100);
    },
    openVideo(video) {
      this.dialogVideo = true;
      this.video = video;
    },
    handleClose(done) {
      done();
      this.dialogVideo = false;
      this.video = "";
    },
    // 标签切换
    isActive(m) {
      this.displayFlg = "list";
      if (m === "1") {
        this.getCPYHonor();
      } else if (m === "2") {
        this.getCPYAlbum();
      } else if (m === "3") {
        this.getCPYVideo();
        // }else if(m==='4'){
        //   this.getCPYVideo();
      } else if (m === "0") {
        this.getCPYProfiles();
      }

      let el = this.$refs.navtag.childNodes;
      for (let i = 0; i < el.length; i++) {
        if (el[i].className === "active") {
          el[i].className = "";
        }
      }
      this.activeShow = m;
      el[parseInt(m)].className = "active";
    },
    //获取公司简介
    // /queryCPY_ProfilesByLanguage
    getCPYProfiles() {
      this.$axios({
        url: "/queryCPY_ProfilesByLanguage",
        method: "GET",
        params: {
          type: this.languageType,
        },
      }).then((res) => {
        this.CPyProfile = res.data.value ? res.data.value : {};
      });
    },
    //获取公司相册
    getCPYAlbum() {
      this.page1.language = this.languageType;
      this.$axios({
        url: "/searchCooperativeCustomers",
        method: "GET",
        params: this.page1,
      }).then((res) => {
        this.CPYAlbum = res.data.value.value;
        this.total1 = res.data.value.total;
      });
    },

    //公司荣誉数据
    getCPYHonor() {
      // this.page = this.$store.state.languageType;
      this.$axios({
        url: "/queryCPY_Honor",
        method: "GET",
        params: this.page,
      }).then((res) => {
        this.rongYuList = res.data.value.value;
        this.total = res.data.value.total;
      });
    },
    //视屏中心数据
    getCPYVideo() {
      // this.page2 = this.$store.state.languageType;
      this.$axios({
        url: "/queryCPY_Video",
        method: "GET",
        params: this.page2,
      }).then((res) => {
        this.CPYVideo = res.data.value.value;
        this.total2 = res.data.value.total;
      });
    },

    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.page.currentPage = val;
      this.getCPYHonor();
    },
    handleCurrentChange1(val) {
      this.page1.currentPage = val;
      this.getCPYAlbum();
    },
    handleCurrentChange2(val) {
      this.page2.currentPage = val;
      this.getCPYVideo();
    },
    validFileType(fileName) {
      const index = fileName.lastIndexOf(".");
      const ext = fileName.substring(index + 1);
      if (ext == "mp4") return true;
      return false;
    },
    goDetail(id) {
      this.$axios({
        url: "/getCooperativeCustomersDetail",
        method: "GET",
        params: {
          id: id,
          language: this.languageType,
        },
      }).then((res) => {
        this.detail = res.data.value;
        this.displayFlg = "detail";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-image-viewer__close) {
  color: #ffffff;
}
.gowuye {
  width: 100%;
  height: 100%;
  background-color: #fff;

  .gowuye-titles {
    margin-top: 74px;
    text-align: center;

    .gowuye-title {
      font-size: 50px;
      font-weight: Normal;
      color: #4d4d4d;
    }
  }

  .gowuye-main {
    width: 1660px;
    margin: 8px auto 128px;

    .gowuye-main-xian {
      height: 3px;
      background: #808080;
    }

    .gowuye-main-nav {
      margin-top: 30px;
      width: 1660px;
      height: 86px;
      background: #f2f2f2;
      display: flex;
      justify-content: space-around;

      .active:after {
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        top: 64px;
        left: 0px;
        //bottom: 20px;
        background-color: #1850af;
        //border-bottom: 3px solid;
      }

      div {
        cursor: pointer;
        position: relative;
        line-height: 86px;
        font-size: 30px;
        font-weight: 400;
        text-align: center;
        color: #4d4d4d;
      }
    }

    .gowuye-main-box {
      margin-top: 14px;

      .rongyu-pagination {
        width: 318px;
        margin: 0 auto;
      }
      .rongyu {
        margin-top: 72px;
        display: grid;
        grid-template-columns: 587px 587px 587px;
        grid-template-rows: 531px 531px 531px;
        grid-row-gap: 38px;

        .rongyu-img {
          width: 486px;
          height: 428px;
          background: #f1f1f1;
          border-radius: 10px 10px 0px 0px;

          .suo-img {
            width: 486rem;
            height: 428rem;
          }
        }
        .co-operation-img {
          width: 486px;
          height: 486px;
          background: #fff;
          border-radius: 20px;
          box-shadow: 0px 0px 10px 0px #ddd;

          .suo-img {
            width: 486rem;
            height: 428rem;
          }
          .title {
            cursor: pointer;
            font-size: 22rem;
            font-weight: 550;
            padding-left: 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .context {
            font-size: 2.8rem;
            padding: 10px;
            color: #aaa;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      .itme {
        margin-top: 62px;

        .item-cent {
          display: flex;

          .gowuye-main-box-lable {
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 30px;
            font-weight: 700;
            text-align: left;
            color: #333333;
          }

          .gowuye-main-box-value {
            display: inline-block;
            width: 1446px;
            font-size: 30px;
            font-weight: 400;
            text-align: left;
            margin-left: 20px;
            color: #808080;
          }
        }

        .xian {
          margin-top: 4px;
          width: 100%;
          height: 1px;
          background: #808080;
        }
      }
    }
  }
}

.detail-title {
  text-align: center;
  font-size: 35rem;
  margin-bottom: 5px;
}
.detail-context {
  font-size: 30rem;
  margin: 15px 10px;
}

:deep(.el-dialog__body),
:deep(.el-dialog__header) {
  padding: 0 !important;
}
.myvideo1 {
  width: 100%;
  height: 100%;
  margin: 0 -8px -5px 0;
}
:deep(.el-dialog__headerbtn) {
  border: 1px solid #ffffff;

  border-radius: 30px;
  z-index: 9999;
}
:deep(.el-dialog__headerbtn .el-dialog__close) {
  color: #ffffff;
}

:deep(.el-carousel__container),
my-carousel {
  height: 580px !important;
}
</style>
